/**
*** PAGE GLOBALE ***
**/
.container-admin-spectator {
  height: 100vh;
  flex: 1;
	display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 24px 24px 24px;
	background-color: var(--background);
  box-sizing: border-box;
}

/* container context */
.container-admin-spectator > #container-context {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #dcd9cd;
  filter: var(--shadow);
  box-sizing: border-box;
}

/* container context fade in */
.container-admin-spectator > #container-context.fade-in {
  animation: fadeIn 250ms ease 0s 1 normal none;
}

/* container context fade out */
.container-admin-spectator > #container-context.fade-out {
  animation: fadeOut 250ms ease 0s 1 normal none;
}

/* Animation fade out */
@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-250px);
	}
}

/* Animation fade in */
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

/* points */
.container-admin-spectator > #container-context > #points {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* theme */
.container-admin-spectator > #container-context > #theme {
  margin: 0px 0px 0px 24px;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* label */
.container-admin-spectator > h1 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 28px;
  color: var(--text);
  text-align: center;
}

/* container elements */
.container-admin-spectator > #container-elements {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 12px 0px;
}

/* audio controls */
.container-admin-spectator > #container-elements > #container-audio > #audio-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: var(--background-darker);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 0.2);
}

/* audio indication */
.container-admin-spectator > #container-elements > #container-audio > .indication {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

/* audio controls icones */
.container-admin-spectator > #container-elements > #container-audio > #audio-controls > svg {
  color: var(--text);
}

/* audio controls icones */
.container-admin-spectator > #container-elements > #container-audio > #audio-controls > input {
  background-color: var(--primary);
}

/* image */
.container-admin-spectator > #container-elements > img {
  flex: auto;
  width: 100%;
  height: 150px; /* besoin de ce style pour que l'image prenne toute la place dispo et pas pluss */
  object-fit: contain;
}
.container-admin-spectator > #container-elements > #credit {
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--text);
  text-align: center;
}

/* container propositions */
.container-admin-spectator > #container-elements > #container-propositions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;
  margin-top: 12px;
}

/* réponse texte indication */
.container-admin-spectator > #container-elements > .indication {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

/* dessin default background */
.container-admin-spectator > #container-elements > .with-background #react-sketch-canvas__canvas-background {
  fill: transparent;
}

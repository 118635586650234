/**
*** PAGE GLOBALE ***
**/
/* container global */
.container-forgot-password {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
	background-color: var(--background);
}
/* titre */
.container-forgot-password > h1 {
  margin-top: 4rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC FORMULAIRE ***
**/
/* container global */
.container-forgot-password > form {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* label */
.container-forgot-password > form > .custom-label  {
  align-self: flex-start;
}
/* bouton confirmer */
.container-forgot-password > form > .custom-button:last-of-type  {
  margin-top: 64px;
}

/**
*** BLOC TEXTE CONFIRMATION ***
**/
/* container global */
.container-forgot-password > .container-confirmation-text {
  padding: 0px 12px;
  box-sizing: border-box;
}
/* texte */
.container-forgot-password > .container-confirmation-text > p {
  font-size: 16px;
  font-family: var(--font-family-regular);
  color: var(--text);
}

/**
*** PAGE GLOBALE ***
**/
.breadcrumbs {
  margin: 12px 0px;
}

/**
*** BOUTON ***
**/
.breadcrumbs > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/**
*** ICONE ***
**/
.breadcrumbs > button > svg {
  color: var(--subtitle);
}

/**
*** TEXTE ***
**/
.breadcrumbs > button {
  font-family: MontserratRegular;
  font-size: 14px;
  color: var(--subtitle);
}

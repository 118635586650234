/**
*** PAGE GLOBALE ***
**/
.stroke-link {
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-family: MontserratBold;
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
  text-decoration: none;
}

.stroke-link:hover {
  background-color: var(--primary);
  color: var(--input-background);
}

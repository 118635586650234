/**
*** PAGE GLOBALE ***
**/
.container-terms {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 24px 48px 24px;
	background-color: var(--background);
}

/* titre */
.container-terms > h1 {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/* titre sections */
.container-terms > h2 {
  width: 100%;
  margin-top: 48px;
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
}

/* contenu sections */
.container-terms > p {
  width: 100%;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
}

/**
*** PAGE GLOBALE ***
**/
.container-form-random-question {
  min-height: calc(100vh - 75px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
  background-color: var(--background);
}

/**
*** BLOC TITRE ***
**/
/* container global */
.container-form-random-question > .container-title {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 8rem;
  margin-bottom: 64px;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* texte titre */
.container-form-random-question > .container-title > h1 {
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  padding: 0px;
  margin: 8px 0px;
}

/**
*** BLOC FORMULAIRE ***
**/
/* container global */
.container-form-random-question > form {
  width: 100%;
  max-width: var(--max-width);
  padding: 0px 12px;
  box-sizing: border-box;
}
/* container input */
.container-form-random-question > form > .container-input {
  width: 100%;
  margin: 60px 0px;
}
/* container input first */
.container-form-random-question > form > .container-input:first-of-type {
  margin-top: 0px;
}
/* container slider */
.container-form-random-question > form > .container-input > .container-slider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
/* texte slider */
.container-form-random-question > form > .container-input > .container-slider > p {
  width: 50px;
  font-family: var(--font-family-regular);
  font-size: 18px;
  color: var(--text);
  text-align: center;
}
/* slider */
.container-form-random-question > form > .container-input > .container-slider > input[type="range"] {
  width: 50%;
}
/* button check all themes */
.container-form-random-question > form > .container-input > button {
  margin-bottom: 24px;
  padding: 4px 8px;
  font-size: 12px;
  font-family: var(--font-family-regular);
  color: var(--input-background);
  border: none;
  border-radius: 4px;
  background-color: #0267FD;
  cursor: pointer;
}
/* container checkboxes */
.container-form-random-question > form > .container-input > .container-checkboxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 12px 12px;
}

/* container confirm button */
.container-form-random-question > form > .container-confirm-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

/* responsive, en-dessous de 800px */
@media (max-width: 800px) {
  .container-form-random-question > form > .container-input > .container-checkboxes {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* responsive, en-dessous de 600px */
@media (max-width: 600px) {
  .container-form-random-question > form > .container-input > .container-checkboxes {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* responsive, en-dessous de 400px */
@media (max-width: 400px) {
  .container-form-random-question > form > .container-input > .container-checkboxes {
    grid-template-columns: repeat(2, 1fr);
  }
}

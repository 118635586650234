/**
*** PAGE GLOBALE ***
**/
.modal {
  position: fixed;
  padding: 0px;
  border: 0;
  border-radius: 8px;
}

.modal > .container-content {
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  background-color: var(--background);
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.modal > .container-content > p {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

.modal > .container-content > .container-buttons {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}

/* bouton annuler */
.modal > .container-content > .container-buttons > .custom-button:first-of-type {
  color: var(--text) !important;
  background-color: transparent !important;
}

/**
*** PAGE GLOBALE ***
**/
.container-form-question {
  min-height: calc(100vh - 75px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
  background-color: var(--background);
}

/**
*** BLOC TITRE ***
**/
/* container global */
.container-form-question > .container-title {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 8rem;
  margin-bottom: 64px;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* texte titre */
.container-form-question > .container-title > h1 {
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  padding: 0px;
  margin: 8px 0px;
}
/* note titre */
.container-form-question > .container-title > p {
  text-align: center;
  font-style: italic;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--subtitle);
}

/**
*** BLOC FORMULAIRE ***
**/
/* container global */
.container-form-question > form {
  width: 100%;
  max-width: var(--max-width);
  padding: 0px 12px;
  box-sizing: border-box;
}
/* container input */
.container-form-question > form > .container-input {
  width: 100%;
  margin: 60px 0px;
}
/* container input first */
.container-form-question > form > .container-input:first-of-type {
  margin-top: 0px;
}
/* inputs */
.container-form-question > form > .container-input > .container-custom-input > .custom-input {
  width: 100%;
  max-width: var(--max-width);
}
/* label de second niveau des inputs */
.container-form-question > form > .container-input > .label-second {
  margin-top: 14px;
  font-size: 14px;
  font-family: var(--font-family-regular);
  color: var(--text);
}
/* container titre des inputs */
.container-form-question > form > .container-input > .container-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
/* container titre des inputs */
.container-form-question > form > .container-input > .container-label > .action-button {
  padding: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-family: var(--font-family-regular);
  color: var(--input-background);
  border: none;
  border-radius: 4px;
  background-color: var(--error);
  cursor: pointer;
}
/* bouton action inputs */
.container-form-question > form > .container-input > .action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 14px 0px;
  padding: 4px 8px;
  font-size: 12px;
  font-family: var(--font-family-regular);
  color: var(--input-background);
  border: none;
  border-radius: 4px;
  background-color: #0267FD;
  cursor: pointer;
}
/* bouton action inputs disabled */
.container-form-question > form > .container-input > .action-button:disabled {
  opacity: .6;
  cursor: default;
}

/* container prévisualisation image */
.container-form-question > form > .container-input > .container-image {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: var(--light-gray);
}
/* prévisualisation image */
.container-form-question > form > .container-input > .container-image > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* message si pas d'image */
.container-form-question > form > .container-input > .container-image > p {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* container prévisualisation audio */
.container-form-question > form > .container-input > .container-audio {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: var(--light-gray);
}
/* message si pas d'audio */
.container-form-question > form > .container-input > .container-audio > p {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* container checkbox text format */
.container-form-question > form > .container-input > .container-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* label checkbox text format */
.container-form-question > form > .container-input > .container-checkbox > label {
  margin-left: 14px;
  font-size: 14px;
  font-family: var(--font-family-regular);
  color: var(--text);
}

/* checkbox text format */
.container-form-question > form > .container-input > .container-checkbox > input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: var(--text);
  width: 20px;
  height: 20px;
  border: 2px solid var(--text);
  border-radius: 3px;
  transform: translateY(-0.075em);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

/* checkbox text format checked element */
.container-form-question > form > .container-input > .container-checkbox > input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #0267FD;

  transform-origin: center;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

/* checkbox text format checked element animation when checked */
.container-form-question > form > .container-input > .container-checkbox > input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* container prévisualisation dessin */
.container-form-question > form > .container-input > .container-drawing {
  position: relative;
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: var(--light-gray);
}

/* dessin default background */
.container-form-question > form > .container-input > .container-drawing #react-sketch-canvas__canvas-background {
  fill: transparent;
}

/* container propositions */
.container-form-question > form > .container-input > .container-propositions {
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
}
/* container propositions responsive mobile, en-dessous de 900px */
@media (max-width: 900px) {
  .container-form-question > form > .container-input > .container-propositions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 12px;
  }
}
/* container propositions responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .container-form-question > form > .container-input > .container-propositions {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* container confirm button */
.container-form-question > form > .container-confirm-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

/* custom text button des propositions */
.container-form-question > form > .container-input > .custom-text-button {
  padding: 0px;
  margin: 0px 0px 12px 0px;
}

/* container points input */
.container-form-question > form > .container-input > .container-points-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* texte points input */
.container-form-question > form > .container-input > .container-points-input > p {
  width: 120px;
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 48px;
  color: var(--text);
  text-align: center;
}

/* boutons points input */
.container-form-question > form > .container-input > .container-points-input > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-size: 18px;
  border: none;
  border-radius: 3px;
  background-color: #0267FD;
  color: var(--white);
  cursor: pointer;
}
/* boutons points input disabled */
.container-form-question > form > .container-input > .container-points-input > button:disabled {
  opacity: .6;
  cursor: default;
}
/* notes */
.container-form-question > form .note {
  text-align: left;
  font-style: italic;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--subtitle);
}

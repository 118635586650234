/**
*** PAGE GLOBALE ***
**/
.container-player-session {
  height: 100vh;
  width: 100vw;
	display: flex;
  flex-direction: row;
  justify-content: flex-start;
	background-color: var(--background);
  overflow-x: hidden;
  overflow-y: hidden;
}

/**
*** SECTION GAUCHE ***
**/
/* container */
.container-player-session > #container-left-content {
  height: 100vh;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
	background-color: var(--background-darker);
}

/* container haut */
.container-player-session > #container-left-content > div {
  height: 1px; /* utile pour que la liste des joueurs ne prennent pas plus de hauteur que la fenêtre */
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* nom du quiz */
.container-player-session > #container-left-content > div > h1 {
  margin: 24px 0px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--text);
  text-align: center;
}

/* container in game player cards */
.container-player-session > #container-left-content > div > #player-list {
  width: 100%;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

/* in game player card */
.container-player-session > #container-left-content > div > #player-list > .in-game-player-card {
  display: inline-block;
  margin: 10px 0px;
}

/* container buzzer */
.container-player-session > #container-left-content > .container-buzzer {
  position: relative;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  background-color: #e7e0bf;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
}

/* container buzzer volume active */
.container-player-session > #container-left-content > .container-buzzer > .container-toggle {
  position: absolute;
  bottom: 6px;
  right: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* buzzer volume texte */
.container-player-session > #container-left-content > .container-buzzer > .container-toggle > p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 11px;
  color: var(--text);
  text-align: center;
}

/**
*** SECTION DROITE ***
**/
/* container */
.container-player-session > #container-right-content {
  height: 100vh;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 24px 24px 24px;
	background-color: var(--background);
}

/* container context */
.container-player-session > #container-right-content > #container-context {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #dcd9cd;
  filter: var(--shadow);
  box-sizing: border-box;
}

/* container context fade in */
.container-player-session > #container-right-content > #container-context.fade-in {
  animation: fadeIn 250ms ease 0s 1 normal none;
}

/* container context fade out */
.container-player-session > #container-right-content > #container-context.fade-out {
  animation: fadeOut 250ms ease 0s 1 normal none;
}

/* Animation fade out */
@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-250px);
	}
}

/* Animation fade in */
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

/* points */
.container-player-session > #container-right-content > #container-context > #points {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* theme */
.container-player-session > #container-right-content > #container-context > #theme {
  margin: 0px 0px 0px 24px;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* label */
.container-player-session > #container-right-content > h1 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 28px;
  color: var(--text);
  text-align: center;
}

/* container elements */
.container-player-session > #container-right-content > #container-elements {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 12px 0px;
}

/* audio controls */
.container-player-session > #container-right-content > #container-elements > #container-audio > #audio-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: var(--background-darker);
  box-shadow: 0px 0px 4px rgb(0 0 0 / 0.2);
}

/* audio indication */
.container-player-session > #container-right-content > #container-elements > #container-audio > .indication {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

/* audio controls icones */
.container-player-session > #container-right-content > #container-elements > #container-audio > #audio-controls > svg {
  color: var(--text);
}

/* audio controls icones */
.container-player-session > #container-right-content > #container-elements > #container-audio > #audio-controls > input {
  background-color: var(--primary);
}

/* image */
.container-player-session > #container-right-content > #container-elements > img {
  flex: auto;
  width: 100%;
  height: 150px; /* besoin de ce style pour que l'image prenne toute la place dispo et pas pluss */
  object-fit: contain;
}
.container-player-session > #container-right-content > #container-elements > #credit {
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--text);
  text-align: center;
}

/* container propositions */
.container-player-session > #container-right-content > #container-elements > #container-propositions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 3rem;
  margin-top: 12px;
}

/* réponse texte indication */
.container-player-session > #container-right-content > #container-elements > .indication {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

/* dessin default background */
.container-player-session > #container-right-content > #container-elements > .with-background #react-sketch-canvas__canvas-background {
  fill: transparent;
}

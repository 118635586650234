:root {
  --white: #ffffff;
  --lightGrey: #a8a8a8;
  --darkBlue: #001b39;
  --mainBlue: #002f5d;
  --lightBlue: #19436d;
  --lightOrange: #fba625;
  --mainOrange: #fe8405;
  --mainOrangeTransparent: #fedab4;
  --lightred: #ff5b46;

  --boPrimary: #5872d7;
  --game-background: #05103a;
  
  --light-grey: #CCCCCC;
  --dark1: #37373D;
  --dark2: #252526;
  --dark3: #1E1E1E;
  --blue-light: #62B2E9;
  --blue-dark: #448CC2;
  --orange-light: #D28445;
  --orange-dark: #A66C3D;
  --red: #EB5252;

  --background: #FCFBF7;
  --background-mid: #F8F5EC;
  --background-darker: #F5F2E4;
  --input-background: #FDFDFE;
  --input-background-disabled: #E3E3E4;
  --primary: #FD9802;
  --subtitle: #A19DA2;
  --subtitle-opacity: #f5f5f5;
  --text: #2E293F;
  --text-light: #39334e;
  --card-background: #FDFDFE;
  --card-background-darker: #e3e3e4;
  --error: #F85758;
  --error-opacity: #fccccc;
  --error-bold: #f50e0f;
  --color-1: #2E8BF3;
  --color-1-light: #D2E3DF;
  --light-gray: #E0DEE0;
  --green: #90C57F;
  --green-opacity: #e1efdc;
  --green-darker: #294620;

  --max-width: 1050px;

  --shadow: drop-shadow(0px 4px 4px #0000001F);
  --shadow-light: drop-shadow(0px 1px 1px #0000001F);
  --shadow-strong: drop-shadow(0px 4px 4px #0000003F);

  --font-family-regular: MontserratRegular;--font-family-bold: MontserratBold;
  --font-family-black: MontserratBlack;
  
  --font-family-fira: FiraMono;
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'MontserratBlack';
  src: local('MontserratBlack'), url(./fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraMono';
  src: local('FiraMono'), url(./fonts/FiraMono/FiraMono-Medium.ttf) format('truetype');
}

/**
*** PAGE GLOBALE ***
**/
.container-player-leaderboard {
  height: 100vh;
  width: 100vw;
	display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
	background-color: var(--background);
  box-sizing: border-box;
}

/* label quiz */
.container-player-leaderboard > h1 {
  margin: 8rem 0px 0px 0px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/* admin */
.container-player-leaderboard > .admin-username {
  width: 100%;
  max-width: var(--max-width);
  margin: 24px 0px;
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

/* admin label */
.container-player-leaderboard > .admin-username > span {
  font-family: var(--font-family-bold);
}

/* container liste joueurs */
.container-player-leaderboard > .container-list-players {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* card joueurs */
.container-player-leaderboard > .container-list-players > .player-result {
  margin: 6px 0px;
}

/**
*** LOGO ***
**/
.container-player-leaderboard > .container-logo {
  position: absolute;
  top: 12px;
  left: 12px;
}

.container-player-leaderboard > .container-logo > img {
  width: 100px;
}

/**
*** PAGE GLOBALE ***
**/
/* container */
#container-proposition-bloc {
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 0.2);
}

/* label */
#container-proposition-bloc > p {
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

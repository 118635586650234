/**
*** PAGE GLOBALE ***
**/
.pricing-card {
  min-height: 400px;
  min-width: 200px;
  padding: 12px 18px;
  border-radius: 4px;
  background-color: var(--background-darker);
  filter: var(--shadow);
  box-sizing: border-box;
}

/* title */
.pricing-card h2 {
  margin: 0px;
  font-size: 16px;
  font-family: var(--font-family-black);
  color: #49442D;
}

/* paragraphs */
.pricing-card p {
  margin: 0px;
}

/* container price */
.pricing-card > .container-price {
  margin-top: 24px;
  margin-bottom: 48px;
}
/* price */
.pricing-card > .container-price > .price {
  margin: 0px;
  font-size: 48px;
  font-family: var(--font-family-fira);
}
/* subprice */
.pricing-card > .container-price > .subprice {
  font-size: 11px;
  font-family: var(--font-family-fira);
}

/* feature */
.pricing-card > .feature {
  font-size: 14px;
  font-family: var(--font-family-regular);
}
/* feature */
.pricing-card > .feature:not(:last-child) {
  margin-bottom: 18px;
}

/* bouton action */
.pricing-card > button {
  padding: 8px 24px;
  margin-top: 48px;
  border: none;
  border-radius: 2px;
  background-color: #0267FD;
  cursor: pointer;
}
/* bouton action label */
.pricing-card > button > p {
  color: #FFF;
  font-size: 18px;
  font-family: var(--font-family-bold);
}
/* bouton action hover */
.pricing-card > button:hover {
  background-color: #0252ca;
}
/* bouton action disabled */
.pricing-card > button:disabled {
  background-color: #90C57F;
  cursor: default;
}

.container-quizzcard {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
	background-color: var(--card-background);
  filter: var(--shadow);
  padding: 12px;
  cursor: pointer;
}

.container-quizzcard:hover {
	background-color: var(--card-background-darker);
}

.container-quizzcard > h1 {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 18px;
  color: var(--text-light);
}

.container-quizzcard > p {
  margin: 0px;
  margin-top: 10px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--subtitle);
}

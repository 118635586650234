/**
*** PAGE GLOBALE ***
**/
.container-quizzdetails {
  min-height: calc(100vh - 75px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
  padding: 0px 12px;
  box-sizing: border-box;
  background-color: var(--background);
}

/**
*** BLOC METADATA ***
**/
/* container global */
.container-quizzdetails > .container-metadata {
  width: 100%;
  max-width: var(--max-width);
  margin-top: 8rem;
  padding: 12px 0px;
  border-radius: 8px;
  filter: var(--shadow);
  box-sizing: border-box;
}

/* container titre */
.container-quizzdetails > .container-metadata > .container-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

/* titre */
.container-quizzdetails > .container-metadata > .container-title > h1 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
}

/* texte titre du quiz */
.container-quizzdetails > .container-metadata > .container-custom-input {
  margin-bottom: 24px;
}

/**
*** BLOC TITRE ***
**/
/* container global */
.container-quizzdetails>.container-title {
  width: 100%;
  max-width: var(--max-width);
  margin-top: 8rem;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: var(--background-darker);
  filter: var(--shadow);
  box-sizing: border-box;
}

/* container titre et boutons actions */
.container-quizzdetails>.container-title>.container-title-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* container global responsive mobile, en-dessous de 900px */
@media (max-width: 900px) {
  .container-quizzdetails>.container-title {
    flex-wrap: wrap;
  }
}

/* container textes à gauche */
.container-quizzdetails>.container-title>.container-title-content>.container-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* texte titre */
.container-quizzdetails>.container-title>.container-title-content>.container-texts>.text-input {
  font-family: var(--font-family-bold);
  font-size: 24px;
  color: var(--text);
  margin: 8px 0px;
}

/* texte date de création */
.container-quizzdetails>.container-title>.container-title-content>.container-texts>p:last-of-type {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  margin: 8px;
}

/* container boutons à droite */
.container-quizzdetails>.container-title>.container-title-content>.container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* container boutons à droite responsive mobile, en-dessous de 900px */
@media (max-width: 900px) {
  .container-quizzdetails>.container-title>.container-title-content>.container-buttons {
    width: 100%;
    justify-content: flex-start;
    margin-top: 48px;
  }
}

/* container boutons à droite responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .container-quizzdetails>.container-title>.container-title-content>.container-buttons {
    width: 100%;
    justify-content: center;
  }
}

/* bouton "supprimer" */
.container-quizzdetails>.container-title>.container-title-content>.container-buttons>.custom-text-button {
  margin-right: 48px;
}

/**
*** BLOC LIENS ***
**/
/* container global */
.container-quizzdetails>.container-links {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;
  margin-top: 42px;
  border-radius: 8px;
  background-color: var(--background-darker);
  filter: var(--shadow);
}

/* titre du container des liens */
.container-quizzdetails>.container-links>.title-links {
  font-family: var(--font-family-bold);
  font-size: 16px;
  color: var(--text);
}

/* note du container des liens */
.container-quizzdetails>.container-links>.note {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
}

/* bouton ouvrir session du container des liens */
.container-quizzdetails>.container-links>.custom-button {
  margin: 24px 0px;
}

/* container de chaque lien avec son label */
.container-quizzdetails>.container-links>.container-bloc-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

/* label du lien */
.container-quizzdetails>.container-links>.container-bloc-link>p:first-of-type {
  width: calc(100% - 60px);
  margin: 12px 0px;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--text);
}

/* notes du label du lien */
.container-quizzdetails>.container-links .note-links {
  font-style: italic;
  font-family: var(--font-family-regular);
  font-size: 11px;
  color: var(--text);
}

/* input du lien */
.container-quizzdetails>.container-links>.container-bloc-link>div {
  width: calc(100% - 72px);
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: var(--card-background);
}

/* container icones dans l'input du lien */
.container-quizzdetails>.container-links>.container-bloc-link>div>.container-icons {
  display: flex;
  flex-direction: row;
}

/* icone visibilité dans l'input du lien */
.container-quizzdetails>.container-links>.container-bloc-link>div>.container-icons>button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--text);
}

/* texte contenu dans l'input du lien */
.container-quizzdetails>.container-links>.container-bloc-link>div>p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* icone de copie du lien */
.container-quizzdetails>.container-links>.container-bloc-link>div>.container-icons>svg {
  margin-left: 8px;
  color: var(--text);
  cursor: pointer;
}

/**
*** BLOC BOUTON AJOUTER UNE QUESTION ***
**/
/* container global */
.container-quizzdetails>.container-add-question {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 42px 0px;
}

/* boutons */
.container-quizzdetails>.container-add-question>.custom-button {
  margin-right: 12px;
  background-color: #0267FD !important;
}

/**
*** BLOC QUESTIONS ***
**/
/* container global */
.container-quizzdetails>.container-questions {
  width: 100%;
  max-width: var(--max-width);
}

/* texte pas encore de questions */
.container-quizzdetails>.container-questions>.text-no-questions {
  margin: 42px 0px 84px 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--subtitle);
  text-align: center;
}

/* bloc question */
.container-quizzdetails>.container-questions>.container-question-bloc {
  margin-bottom: 24px;
}

/* container global responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .container-quizzdetails>.container-questions>.container-question-bloc>.container-position {
    padding: 21px 12px 21px 42px;
  }

  .container-quizzdetails>.container-questions>.container-question-bloc>.container-position>.circle-position {
    transform: scale(.75, .75);
  }
}

/**
*** SECTION NOT FOUND ***
**/
/* container */
.container-quizzdetails>.container-no-quizz-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* titre */
.container-quizzdetails>.container-no-quizz-found>h1 {
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--primary);
  text-align: center;
}

/* texte */
.container-quizzdetails>.container-no-quizz-found>p {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC LOADER ***
**/
#container-loader>.loader {
  border-bottom-color: var(--primary);
}

/**
*** PAGE GLOBALE ***
**/
.container-admin-lobby {
  min-height: 100vh;
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	background-color: var(--background);
}

/**
*** TITRE ***
**/
.container-admin-lobby > .title {
  margin-top: 8rem;
  margin-bottom: 24px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC CONTENU ***
**/
.container-admin-lobby > .container-content {
  max-width: var(--max-width);
  width: 100%;
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 12px;
  box-sizing: border-box;
}

/* labels */
.container-admin-lobby > .container-content > .custom-label {
  margin-bottom: 12px;
}

/* labels "participants" */
.container-admin-lobby > .container-content > .custom-label:last-of-type {
  margin-top: 64px;
}

/* container ligne joueur */
.container-admin-lobby > .container-content > .player-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

/* exclure joueur */
.container-admin-lobby > .container-content > .player-line > button:last-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  padding: 6px 8px;
  border: none;
  border-radius: 4px;
  background-color: var(--error);
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--white);
  cursor: pointer;
  filter: var(--shadow);
}

/* indication */
.container-admin-lobby > .indication {
  align-self: center;
  margin: 48px 0px 24px 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  font-style: italic;
  text-align: center;
}

/* bouton lancer le quiz */
.container-admin-lobby > .custom-button:last-of-type {
  margin: 24px 0px;
}

/**
*** LOGO ***
**/
.container-admin-lobby > .container-logo {
  position: absolute;
  top: 12px;
  left: 12px;
}

.container-admin-lobby > .container-logo > img {
  width: 100px;
}


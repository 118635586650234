/**
*** PAGE GLOBALE ***
**/
.not-started-player-session  {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**
*** CARDS ***
**/
/* container cards */
.not-started-player-session > .container-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 3rem;
  padding: 0px 24px;
}

.not-started-player-session > p  {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

.not-started-player-session > .indication  {
  margin-top: 10rem;
  font-style: italic;
}

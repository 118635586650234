/**
*** PAGE GLOBALE ***
**/
.container-checkout {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background);
}

/**
*** CONTENU ***
**/
/* titre */
.container-checkout > h1 {
  margin-top: 4rem;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--primary);
}

/* texte */
.container-checkout > p {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
}

/**
*** BOUTON ***
**/
.container-checkout > .custom-text-button {
  margin-top: 200px;
}

/**
*** PAGE GLOBALE ***
**/
#audio-player {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  background-color: var(--color-1);
  border-radius: 8px;
}

/* bouton play/pause */
#audio-player > button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-1);
  border: none;
  border-radius: calc(32px / 2);
  cursor: pointer;
}

/* icone play/pause */
#audio-player > button > svg {
  color: var(--card-background);
}

/* séparateur */
#audio-player > #separator {
  width: 1px;
  height: 60%;
  margin: 0px 12px;
  background-color: var(--text);
  opacity: 0.12;
}

/* container controle volume */
#audio-player > #volume-control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* barre volume */
#audio-player > #volume-control > input[type='range'] {
  accent-color: var(--card-background);
}

/* volume icones */
#audio-player > #volume-control > svg {
  color: var(--text);
}

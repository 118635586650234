/**
*** PAGE GLOBALE ***
**/
.container-header {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  background-color: var(--background);
  filter: var(--shadow);
}

/**
*** CONTENU HEADER ***
**/
.container-header > .header-content {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background);
}

/**
*** CONTENU A GAUCHE ***
**/
.container-header > .header-content > .contentLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* bouton logo */
.container-header > .header-content > .contentLeftContainer > button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
/* logo */
.container-header > .header-content > .contentLeftContainer > button > img {
  width: 90px;
  height: 'auto';
}

/**
*** BOUTON ICONE MENU ***
**/
.container-header > .header-content > .menu-icon {
  display: none;
  border: none;
  background-color: transparent;
  color: var(--text);
  cursor: pointer;
}

/* responsive, en-dessous de 600px */
@media (max-width: 600px) {
  .container-header > .header-content > .menu-icon {
    display: block;
  }
}

/**
*** MENU INLINE ***
**/
.container-header > .header-content > .container-inline-header > .contentRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container-header > .header-content > .container-inline-header > .contentRightContainer > .custom-text-link {
  margin: 0px 24px 0px 24px;
}

/* responsive, en-dessous de 600px */
@media (max-width: 600px) {
  .container-header > .header-content > .container-inline-header {
    display: none;
  }
}

/**
*** MENU COLUMN ***
**/
.container-header > .container-column-header > .contentRightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--background-darker);
}

.container-header > .container-column-header > .contentRightContainer > .custom-text-button,.custom-text-link {
  margin: 12px 0px 12px 0px;
  padding: 0px;
}

/* responsive, au-dessus de 600px */
@media (min-width: 600px) {
  .container-header > .container-column-header {
    display: none;
  }
}

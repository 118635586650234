.container-custom-input {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: var(--input-background);
  filter: var(--shadow);
}

.container-custom-input > .custom-input {
  flex: 1;
  height: 100%;
  padding: 0px 12px;
  font-family: MontserratRegular;
  font-size: 14px;
  color: var(--text);
  border-width: 0px;
  border-radius: 8px;
  outline: none;
  box-sizing: border-box; /* change le modèle de box pour ne pas que la largeur change avec le padding. Source : https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
  background-color: var(--input-background);
}

.custom-input::placeholder {
  color: var(--subtitle);
  opacity: 1;
}

/* Bouton icone visibilité */
.container-custom-input > button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px 0px 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* Icone visibilité */
.container-custom-input > button > svg {
  color: var(--text);
}

/* Input disabled */
.container-custom-input > .custom-input:disabled {
  background-color: var(--input-background-disabled);
}

.custom-text-button {
  background-color: transparent;
  font-size: 16px;
  font-family: MontserratRegular;
  cursor: pointer;
  border: none;
}

.custom-text-button:disabled {
  opacity: .6;
  cursor: default;
}

.custom-text-button:hover:enabled {
  font-weight: 900;
}

.custom-text-button > p {
  margin: 0px;
}

.custom-text-button > .container-confirm {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.custom-text-button > .container-confirm > p {
  padding-left: 24px;
  margin: 0px;
  font-weight: 900;
}

.custom-text-button > .container-confirm > svg {
  position: absolute;
  left: 0px;
}

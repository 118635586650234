/**
*** PAGE GLOBALE ***
**/
.player-drawing {
  display: flex;
  flex-direction: column;
  filter: var(--shadow);
}

/**
*** SECTION DRAWING ***
**/
.player-drawing > .container-drawing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  border-radius: 8px 8px 0px 0px;
  background-color: #a52a2a;
}

/* texte username */
.player-drawing > .container-drawing > .username {
  margin: 6px 0px;
  color: var(--light-gray);
  font-family: var(--font-family-bold);
  font-size: 18px;
}

/* dessin default background */
.player-drawing > .container-drawing > .with-background #react-sketch-canvas__canvas-background {
  fill: transparent;
}

/* texte source */
.player-drawing > .container-drawing > .credit {
  color: var(--light-gray);
  font-style: italic;
  font-family: var(--font-family-regular);
  font-size: 11px;
  text-align: center;
}

/**
*** SECTION ACTIONS ***
**/
.player-drawing > .container-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  border-radius: 0px 0px 8px 8px;
  background-color: #DCACAC;
}

/* ligne d'action */
.player-drawing > .container-actions > .line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* textes */
.player-drawing > .container-actions > .line > p {
  margin-right: 14px;
  color: var(--text);
  font-family: var(--font-family-regular);
  font-size: 14px;
}

/* bouton valider réponse */
.player-drawing > .container-actions > .line.validate-answer > .custom-button {
  padding: 6px;
}

/* bouton afficher aux joueurs */
.player-drawing > .container-actions > .line.show-all > .custom-button {
  padding: 6px;
  background-color: #0267FD !important;
}

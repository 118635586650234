.buzzer {
  position:relative;
  display:inline-block;
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
}

.buzzer div {
  background-color:#ff1919;
  display:block;
  position:relative;
  padding: 48px 22px;
  
  -webkit-box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #b21111;
  -moz-box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #b21111;
  box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #b21111;
  
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 65px;

  border: 2px solid #2B1800;
  cursor: pointer;

  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--white);
  text-align: center;
}

.buzzer div.active {
  top: 13px;
  background-color:#990f0f;
  
  -webkit-box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3px 0 #990f0f;
  -moz-box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3pxpx 0 #990f0f;
  box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3px 0 #990f0f;
}

.buzzer div.disabled {
  background-color:#ff8c8c;

  border: 2px solid #958b7f;

  -webkit-box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #d88888;
  -moz-box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #d88888;
  box-shadow :inset 0 0px 0 #FFE5C4, 0 13px 0 #d88888;
}

.buzzer div.active.disabled {
  top: 13px;
  background-color:#cc8787;
  
  -webkit-box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3px 0 #cc8787;
  -moz-box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3pxpx 0 #cc8787;
  box-shadow :inset 0 0px 0 #FFE5C4, inset 0 -3px 0 #cc8787;
}

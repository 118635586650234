.custom-text-link {
  background-color: transparent;
  font-size: 16px;
  font-family: MontserratRegular;
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.custom-text-link:hover {
  font-weight: 900;
}

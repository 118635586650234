/**
*** PAGE GLOBALE ***
**/
/* container global */
.container-register-form {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
	align-items: center;
  padding-bottom: 8rem;
	background-color: var(--background);
}
/* titre */
.container-register-form > h1 {
  margin-top: 4rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC FORMULAIRE ***
**/
/* container global */
.container-register-form > form {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* label */
.container-register-form > form > .custom-label  {
  align-self: flex-start;
}
/* bouton confirmer */
.container-register-form > form > .custom-button:last-of-type  {
  margin-top: 64px;
}

/**
*** BLOC CASE A COCHER CG ***
**/
/* container global */
.container-register-form > form > #container-terms {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0px;
}

/* checkbox */
.container-register-form > form > #container-terms > input[type="checkbox"] {
  margin-right: 12px;
}

/* text */
.container-register-form > form > #container-terms > p {
  font-size: 12px;
  font-family: MontserratRegular;
}

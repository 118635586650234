/**
*** BOUTON ***
**/
.container-custom-checkbox-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 6px;
  border: none;
  border-radius: 8px;
  background-color: var(--card-background);
  filter: var(--shadow);
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
  cursor: pointer;
  opacity: .85;
}

.container-custom-checkbox-card.checked {
  color: var(--card-background);
  background-color: var(--color-1);
  opacity: 1;
}

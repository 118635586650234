/**
*** PAGE GLOBALE ***
**/
.pricing {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 24px 48px 24px;
	background-color: var(--background);
}

/* titre */
.pricing > h1 {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** SECTION CARDS PRICING ***
**/
/* container items pricing */
.pricing > .container-items-pricing {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

/* container pricing */
.pricing > .container-items-pricing > .container-pricing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 64px;
}

/* responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .pricing > .container-items-pricing {
    flex-direction: column;
  }

  .pricing > .container-items-pricing > .container-pricing {
    margin: 32px 0px;
  }
}

/**
*** PAGE GLOBALE ***
**/
.container-proposition-bloc {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: var(--shadow);
}

/* container prop définie */
.container-proposition-bloc > .prop-defined {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

/**
*** BOUTON SUPPRIMER ***
**/
#close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 13px;
  cursor: pointer;
}
/* prop définie close bouton */
#close-button > svg {
  color: var(--error);
}
/* prop définie close bouton hover */
#close-button:hover > svg {
  color: var(--error-bold);
}

/**
*** TEXTAREA ***
**/
#proposition-bloc-textarea {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  border: none;
  border-radius: 8px;
  text-align: center;
  resize: none;
  overflow : auto;
  background-color: var(--subtitle-opacity);
}
/* placeholder */
#proposition-bloc-textarea::placeholder {
  color: var(--subtitle);
}

/**
*** BOUTON CHOISIR REPONSE ***
**/
/* container */
#container-response-button {
  position: absolute;
  bottom: 12px;
}

/* bouton */
#container-response-button > button {
  position: relative;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-family: MontserratRegular;
  background-color: transparent;
  border: none;
  color: var(--green);
  cursor: pointer;
}

/* on hover */
#container-response-button > button:hover {
  color: var(--green-darker);
}

/**
*** PAGE GLOBALE ***
**/
.container-login {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
	background-color: var(--background);
}

/* titre */
.container-login > h1 {
  margin-top: 4rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC FORMULAIRE ***
**/
/* container global */
.container-login > form {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* label */
.container-login > form > .custom-label  {
  align-self: flex-start;
}
/* bouton confirmer */
.container-login > form > .custom-button:last-of-type  {
  margin-top: 64px;
}

/**
*** BLOC BOUTON MDP OUBLIE ***
**/
/* bouton */
.container-login > .custom-text-button {
  margin-top: 32px;
}

.custom-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-size: 14px;
  font-family: MontserratBold;
  border-radius: 8px;
  outline: none;
  border: none;
}

.custom-button:disabled {
  opacity: .6;
}

.custom-button:hover {
  filter: contrast(70%);
}

.custom-button > .loader {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  transform: translate3d(-50%, -50%, 0);
  display: none;
}

.custom-button > span {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**
*** PAGE GLOBALE ***
**/
.container-quizzlist {
  min-height: calc(100vh - 75px);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8rem;
  background-color: var(--background);
}

/**
*** BLOC GLOBAL ***
**/
/* container global */
.container-quizzlist > .container-list-quizzes {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**
*** BLOC TITRE ***
**/
/* container titre */
.container-quizzlist > .container-list-quizzes > .container-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8rem;
  margin-bottom: 64px;
  padding: 0px 12px;
  box-sizing: border-box;
}

/* titre */
.container-quizzlist > .container-list-quizzes > .container-title > .title {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
}

/* titre nombre quiz */
.container-quizzlist > .container-list-quizzes > .container-title > .title > .title-count-quizzes {
  margin-left: 14px;
  font-size: 18px;
  color: var(--text-light);
}

/**
*** BLOC QUIZZES ***
**/
/* container liste quiz */
.container-quizzlist > .container-list-quizzes > .container-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  gap: 12px 12px;
}

/* container liste quiz responsive mobile, en-dessous de 900px */
@media (max-width: 900px) {
  .container-quizzlist > .container-list-quizzes > .container-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 12px;
  }
}

/* container liste quiz responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .container-quizzlist > .container-list-quizzes > .container-cards {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px 12px;
  }
}

/**
*** BLOC LOADER QUIZZES ***
**/
#container-loader > .loader {
  border-bottom-color: var(--primary);
}

/**
*** BLOC NO QUIZZES ***
**/
#container-no-quizzes > p {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--subtitle);
}

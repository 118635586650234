/**
*** PAGE GLOBALE ***
**/
.container-account {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
	align-items: center;
  padding-bottom: 8rem;
  background-color: var(--background);
}

/**
*** BLOC TITRE ***
**/
/* container global */
.container-account > .container-title {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 8rem;
  margin-bottom: 64px;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* texte titre */
.container-account > .container-title > h1 {
  width: 100%;
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
}

/**
*** BLOC FORMULAIRE PSEUDO ***
**/
/* container global */
.container-account > .form-data {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* label */
.container-account > .form-data > .custom-label  {
  color: var(--text);
  align-self: flex-start;
}
/* bouton confirmer */
.container-account > .form-data > .custom-button:last-of-type  {
  margin-top: 64px;
}

/**
*** BLOC FORMULAIRE MDP ***
**/
/* container global */
.container-account > .form-password {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  padding: 0px 12px;
  box-sizing: border-box;
}
/* label */
.container-account > .form-password > .custom-label  {
  color: var(--text);
  align-self: flex-start;
}
/* bouton confirmer */
.container-account > .form-password > .custom-button:last-of-type  {
  margin-top: 64px;
}

/**
*** BLOC FORMULAIRE SUPPRESSION COMPTE ***
**/
/* container global */
.container-account > .form-delete {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 88px;
  padding: 48px 12px;
  border: 2px solid var(--error);
  border-radius: 8px;
  background-color: var(--error-opacity);
  box-sizing: border-box;
}
/* label */
.container-account > .form-delete > .custom-label  {
  color: var(--text);
}
/* texte */
.container-account > .form-delete > .warning-note  {
  font-size: 14px;
  font-family: var(--font-family-regular);
  color: var(--text);
}
/* bouton supprimer */
.container-account > .form-delete > .custom-button:last-of-type  {
  margin-top: 32px;
}
/* confirmation container */
.container-account > .form-delete > .delete-confirmation-container  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
}
/* texte confirmation */
.container-account > .form-delete > .delete-confirmation-container > p  {
  margin-bottom: 24px;
  font-size: 14px;
  font-family: var(--font-family-bold);
  color: var(--text);
}

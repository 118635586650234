/**
*** PAGE GLOBALE ***
**/
.in-game-player-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--card-background);
  filter: var(--shadow);
}

/* texte */
.in-game-player-card > p {
  margin: 0px;
  padding: 6px 12px;
  text-align: center;
}

/* container score */
.in-game-player-card > .container-score {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--color-1);
}

/* container boutons score */
.in-game-player-card > .container-score > .container-buttons-score {
  display: flex;
  flex-direction: column;
  padding: 0px 4px;
}

/* boutons score */
.in-game-player-card > .container-score > .container-buttons-score > button {
  margin: 3px 0px;
  flex: 1;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--input-background);
  border: none;
  border-radius: 2px;
  background-color: #0267FD;
  cursor: pointer;
}

/* boutons score hover */
.in-game-player-card > .container-score > .container-buttons-score > button:hover {
  background-color: #015ce3;
}

/* container texte score */
.in-game-player-card > .container-score > .container-text-score {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* texte score */
.in-game-player-card > .container-score > .container-text-score > p {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--card-background);
}

/* container bouton menu controles */
.in-game-player-card > .container-score > .container-button-menu-controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: transparent;
}

/* icone menu controls */
.in-game-player-card > .container-score > .container-button-menu-controls > svg {
  cursor: pointer;
  color: var(--card-background);
}

/* texte pseudo */
.in-game-player-card > p:last-of-type {
  color: var(--text);
  font-size: 14px;
  overflow-wrap: break-word;
}

/**
*** SECTION CONTROLES ***
**/
/* container */
.in-game-player-card > #container-controls {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-gray);
  border-radius: 0px 0px 8px 8px;
}

/* label réponse */
.in-game-player-card > #container-controls > p:first-of-type {
  margin: 6px 0px;
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--text);
}

/* container réponse */
.in-game-player-card > #container-controls > #container-respond {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

/* réponse bouton annuler */
.in-game-player-card > #container-controls > #container-respond > .custom-button:last-of-type {
  margin-left: 24px;
  background-color: var(--error) !important;
}

/* réponse boutons */
.in-game-player-card > #container-controls > #container-respond > .custom-button {
  padding: 6px;
}

/* container timer */
.in-game-player-card > #container-controls > .timer-buzz {
  position: absolute;
  bottom: -20px;
  left: 40px;
  width: 90px;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: var(--card-background);
  filter: var(--shadow);
  box-sizing: border-box;
}

/* timer icon */
.in-game-player-card > #container-controls > .timer-buzz > svg {
  margin-right: 12px;
}

/* timer */
.in-game-player-card > #container-controls > .timer-buzz > p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

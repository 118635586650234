/**
*** PAGE GLOBALE ***
**/
.container-player-lobby {
  min-height: 100vh;
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	background-color: var(--background);
}

/**
*** TITRE ***
**/
.container-player-lobby > .title {
  margin-top: 8rem;
  margin-bottom: 64px;
  font-family: var(--font-family-bold);
  font-size: 34px;
  color: var(--text);
  text-align: center;
}

/**
*** BLOC CONTENU ***
**/
.container-player-lobby > .container-content {
  max-width: var(--max-width);
  width: 100%;
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 12px;
  box-sizing: border-box;
}

/* labels */
.container-player-lobby > .container-content > .custom-label {
  margin-bottom: 12px;
}

/* labels "participants" */
.container-player-lobby > .container-content > .custom-label:last-of-type {
  margin-top: 64px;
}

/* cards */
.container-player-lobby > .container-content > .player-card {
  margin-bottom: 12px;
}

/**
*** INDICATION ***
**/
.container-player-lobby > .container-content > .indication {
  align-self: center;
  margin: 48px 0px 24px 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  font-style: italic;
  text-align: center;
}

/**
*** LOGO ***
**/
.container-player-lobby > .container-logo {
  position: absolute;
  top: 12px;
  left: 12px;
}

.container-player-lobby > .container-logo > img {
  width: 100px;
}

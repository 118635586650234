/**
*** PAGE GLOBALE ***
**/
.container-home {
  min-height: calc(100vh - 75px);
	display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
	align-items: center;
	background-color: var(--background);
}

/**
*** SECTIONS ***
**/
/* section general */
.container-home > .container-section-general {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	background-color: var(--background-darker);
  box-sizing: border-box;
}

/* section global */
.container-home > .container-section-general > .container-section {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 144px 48px;
  box-sizing: border-box;
}

/* main section */
.container-home > .container-section-general > .container-section.main-section {
  height: 60vh;
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 96px 48px;
  box-sizing: border-box;
}

/* main section logo */
.container-home > .container-section-general > .container-section.main-section > img {
  width: 40vw;
  height: auto;
  object-fit: contain;
  margin-bottom: 88px;
}

/* main section h3 */
.container-home > .container-section-general > .container-section.main-section > h3 {
  margin-bottom: 14px;
  text-align: center;
}

/* main section p */
.container-home > .container-section-general > .container-section.main-section > p {
  font-size: 20px;
  text-align: center;
}

/* background color alterner */
.container-home > .container-section-general:nth-of-type(2n) {
  background-color: var(--background);
}

/* button cta */
.container-home > .container-section-general > a {
  position: absolute;
  bottom: -23px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--white);
  text-decoration: none;
  border-radius: 4px;
  background-color: #0267FD;
  z-index: 1;
}

/* button cta hover */
.container-home > .container-section-general > a:hover {
  background-color: #0252ca;
}

/**
*** TEXTES ***
**/
/* h1 */
.container-home h1 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 64px;
  color: var(--text);
}

/* h2 */
.container-home h2 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 36px;
  color: var(--text);
  text-align: center;
}

/* h3 */
.container-home h3 {
  margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 28px;
  color: var(--text);
  text-align: center;
}

/* p */
.container-home p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 24px;
  color: var(--text);
}

/**
*** SECTION FEATURES ***
**/
/* section global */
.container-home > .container-section-general > .container-section > .container-features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin-top: 85px;
}
/* container feature */
.container-home > .container-section-general > .container-section > .container-features > .container-feature {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: #F5F5F5;
  filter: var(--shadow-strong);
}
/* last container feature */
.container-home > .container-section-general > .container-section > .container-features > .container-feature:last-of-type {
  grid-column: 1 / -1;
  margin: auto;
}
/* title feature */
.container-home > .container-section-general > .container-section > .container-features > .container-feature > h3 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
/* text feature */
.container-home > .container-section-general > .container-section > .container-features > .container-feature > p {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
}

/* features responsive, en-dessous de 900px */
@media (max-width: 900px) {
  .container-home > .container-section-general > .container-section > .container-features {
    grid-template-columns: repeat(1, 1fr);
  }
}

/**
*** SECTION STEPS ***
**/
/* container single steps */
.container-home > .container-section-general > .container-section > .container-single-steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
}

/* container single step */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step {
  height: 164px;
  width: 80%;
  display: flex;
  flex-direction: row;
}

/* container single step container line */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-line {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* container single step line number */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-line > .line-number {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background-color: var(--text-light);
}

/* single step line number text */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-line > .line-number 
> p {
  color: var(--input-background);
  font-size: 28px;
  font-family: var(--font-family-bold);
}

/* single step line number line */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-line > .line {
  width: 4px;
  flex: 1;
  background-color: var(--text);
}

/* container single step content */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;
}

/* single step content title */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-content > h3 {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* single step content text */
.container-home > .container-section-general > .container-section > .container-single-steps > .container-single-step > .container-content > p {
  padding-top: 12px;
  font-size: 18px;
}

/**
*** SECTION TYPES ***
**/
/* section global */
.container-home > .container-section-general > .container-section > .container-items-types {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 85px;
}
/* container type */
.container-home > .container-section-general > .container-section > .container-items-types > .container-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  border-radius: 8px;
  background-color: #F5F5F5;
  filter: var(--shadow-strong);
}
/* titre type */
.container-home > .container-section-general > .container-section > .container-items-types > .container-image > p {
  font-size: 20px;
  font-family: var(--font-family-bold);
}
/* image type */
.container-home > .container-section-general > .container-section > .container-items-types > .container-image > img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 36px;
  border-radius: 4px;
  filter: var(--shadow-light);
}

/* types responsive mobile, en-dessous de 600px */
@media (max-width: 600px) {
  .container-home > .container-section-general > .container-section > .container-items-types {
    grid-template-columns: repeat(1, 1fr);
  }
}

/**
*** PAGE GLOBALE ***
**/
.player-result {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	filter: var(--shadow);
	background-color: var(--card-background);
}

/* container position */
.player-result > .container-position {
	height: 100%;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

/* position texte */
.player-result > .container-position > p {
	margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  text-align: center;
}

/* container username */
.player-result > .container-username {
	flex: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 6px;
}

/* username texte */
.player-result > .container-username > p {
	margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

/* container score */
.player-result > .container-score {
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* score texte */
.player-result > .container-score > p {
	margin: 0px;
  font-family: var(--font-family-bold);
  font-size: 14px;
  color: var(--color-1);
  text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	text-wrap: nowrap;
}

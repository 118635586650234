/**
*** PAGE GLOBALE ***
**/
.container-footer {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  background-color: #212121;
}

/**
*** LIENS ***
**/
.container-footer > a {
  margin: 0px 12px;
  color: #FFF;
  font-size: 11px;
  font-family: var(--font-family-regular);
  text-decoration: none;
}

/* liens hover */
.container-footer > a:hover {
  color: #c8c8c8;
}

/**
*** BLOC GLOBAL ***
**/
.container-question-bloc {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: var(--card-background);
  filter: var(--shadow);
}

/**
*** DND HANDLER ***
**/
/* handler */
.container-question-bloc > .dnd-handler {
  position: absolute;
  height: 100%;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px 0px 0px 8px;
  background-color: #eef6fe;
}
/* icone drag indicator */
.container-question-bloc > .dnd-handler > svg {
  color: var(--subtitle);
}

/**
*** BLOC POSITION ***
**/
.container-question-bloc > .container-position {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 24px 42px 59px;
}
/* cercle position */
.container-question-bloc > .container-position > .circle-position {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
  font-family: var(--font-family-bold);
  font-size: 22px;
  color: var(--card-background);
}

/**
*** BLOC TEXTES ***
**/
.container-question-bloc > .container-texts {
  width: 100%;
  height: 100%;
  padding: 12px 24px 12px 0px;
  box-sizing: border-box;
}
/* label */
.container-question-bloc > .container-texts > p {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  margin: 0px;
}
/* container boutons */
.container-question-bloc > .container-texts > .container-buttons {
  margin-top: 24px;
}
/* boutons */
.container-question-bloc > .container-texts > .container-buttons > .custom-text-button {
  padding: 0px;
}
/* bouton "supprimer" */
.container-question-bloc > .container-texts > .container-buttons > .custom-text-button:last-of-type {
  margin-left: 48px;
}

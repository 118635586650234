/**
*** PAGE GLOBALE ***
**/
.player-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  filter: var(--shadow);
}

/* texte */
.player-card > p {
  padding: 6px 12px;
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
}

/* exclure bouton */
.player-card > button {
  min-height: 100%;
  padding: 0px 12px;
  border: none;
  border-radius: 0px 8px 8px 0px;
  background-color: var(--error);
  cursor: pointer;
  
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--white);
}

/* exclure bouton hover */
.player-card > button:hover {
  background-color: var(--error-bold);
}

.instructions  {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instructions > p  {
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

.instructions span  {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

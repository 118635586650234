/**
*** PAGE GLOBALE ***
**/
.instruction-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--background-darker);
  filter: var(--shadow);
  box-sizing: border-box;
}

/* title */
.instruction-card > h2 {
  margin: 24px 0px;
  font-family: var(--font-family-bold);
  font-size: 20px;
  color: var(--text);
  text-align: center;
}

/* container content */
.instruction-card > p {
  min-height: 70px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/**
*** PAGE GLOBALE ***
**/
.stroke-button {
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-family: MontserratBold;
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.stroke-button:hover {
  background-color: var(--primary);
  color: var(--input-background);
}

.stroke-button:disabled {
  opacity: .6;
}

/* container général */
.dropdown {
  position: absolute;
  width: 100px;
  left: 102%;
  top: 0px;
  display: none;
  margin: 0px;
  padding: 12px 0px;
  border-radius: 8px;
  background-color: var(--card-background);
  list-style-type: none;
}

/* container général si affiché */
.shown {
  display: block;
}

/* container item dropdown */
.dropdown > .dropdown-item {
  padding: 3px 6px;
}
/* container item dropdown 1 sur 2 */
.dropdown > .dropdown-item:nth-of-type(2n) {
  border-top: 1px solid #e3e3e4;
}

/* container item dropdown on hover */
.dropdown > .dropdown-item:hover {
  background-color: var(--card-background-darker);
}

/* item dropdown texte */
.dropdown > .dropdown-item > button {
  font-family: var(--font-family-regular);
  font-size: 14px;
  border: none;
  color: var(--text);
  background-color: transparent;
  cursor: pointer;
}

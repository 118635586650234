/**
*** PAGE GLOBALE ***
**/
.container-admin-session {
  min-height: 100vh;
  width: 100%;
	display: flex;
  flex-direction: row;
  justify-content: flex-start;
	background-color: var(--background);
}

/* textes indication */
.container-admin-session .indication {
  font-family: var(--font-family-bold);
  font-size: 12px;
  color: var(--subtitle);
  text-align: center;
  font-style: italic;
}

/**
*** SECTION GAUCHE ***
**/
/* container */
.container-admin-session > #container-left-content {
  min-height: 100vh;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
	background-color: var(--background-darker);
}

/* container haut */
.container-admin-session > #container-left-content > div {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* nom du quiz */
.container-admin-session > #container-left-content > div > h1 {
  margin: 24px 0px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--text);
  text-align: center;
}

/* in game player card */
.container-admin-session > #container-left-content > div > #player-list > .in-game-player-card {
  display: inline-block;
  margin: 10px 0px;
}

/* buzz volume container */
.container-admin-session > #container-left-content > div > .container-toggle {
  position: absolute;
  bottom: 6px;
  right: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* buzz volume text */
.container-admin-session > #container-left-content > div > .container-toggle > p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 11px;
  color: var(--text);
  text-align: center;
}

/**
*** SECTION DROITE ***
**/
/* container */
.container-admin-session > #container-right-content {
  min-height: 100vh;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	background-color: var(--background);
}

/* container context */
.container-admin-session > #container-right-content > #container-metadata {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 0px 24px;
  box-sizing: border-box;
}

/* context container header */
.container-admin-session > #container-right-content > #container-metadata > .header-question {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* context header numéro Q */
.container-admin-session > #container-right-content > #container-metadata > .header-question > p {
  margin: 6px;
  font-family: var(--font-family-regular);
  font-size: 16px;
  color: var(--text);
  text-align: center;
}

/* context header container timer */
.container-admin-session > #container-right-content > #container-metadata > .header-question > .timer-question {
  width: auto;
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  border-radius: 8px;
  background-color: var(--card-background);
  filter: var(--shadow);
  box-sizing: border-box;
}

/* context header timer icon */
.container-admin-session > #container-right-content > #container-metadata > .header-question > .timer-question > svg {
  margin-right: 12px;
}

/* context header timer */
.container-admin-session > #container-right-content > #container-metadata > .header-question > .timer-question > p:last-of-type {
  width: 75px;
}

/* context header timer */
.container-admin-session > #container-right-content > #container-metadata > .header-question > .timer-question > p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* container elements */
.container-admin-session > #container-right-content > #container-elements {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0px;
}

/* container element */
.container-admin-session > #container-right-content > #container-elements > .container-element {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
}

/* container element background color */
.container-admin-session > #container-right-content > #container-elements > .container-element:nth-of-type(2n) {
  border-width: 2px 0px;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: var(--background-mid);
}

/* container element left content */
.container-admin-session > #container-right-content > #container-elements > .container-element > .left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* element left text */
.container-admin-session > #container-right-content > #container-elements > .container-element > .left-content > p {
  width: 100%;
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: left;
}

/* element left label */
.container-admin-session > #container-right-content > #container-elements > .container-element > .left-content > p > .element-label {
  font-family: var(--font-family-bold);
}

/* container element middle content */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* element middle text */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > p:not(.indication) {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
  overflow-wrap: anywhere;
}

/* element middle image */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > img {
  width: 60%;
  height: auto;
  object-fit: contain;
}

/* element middle image credit */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > .credit {
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--text);
  text-align: center;
}

/* element middle container propositions */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > #element-propositions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  box-sizing: border-box;
  column-gap: 1rem;
}

/* element middle player text response */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > .player-text-response {
  margin: 12px 0px;
}

/* element middle player drawing */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > .player-drawing {
  margin: 12px 0px;
}

/* element middle drawing default background */
.container-admin-session > #container-right-content > #container-elements > .container-element > .middle-content > .with-background #react-sketch-canvas__canvas-background {
  fill: transparent;
}

/* container element right content */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* element right text */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > p:not(.indication) {
  font-family: var(--font-family-regular);
  font-size: 14px;
  color: var(--text);
  text-align: center;
}

/* element right action button */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  font-family: var(--font-family-regular);
  color: var(--input-background);
  border: none;
  border-radius: 4px;
  background-color: #0267FD;
  cursor: pointer;
}

/* element right action button collect */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button.collect-button {
  background-color: #3d9701;
}

/* element right action button disabled */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button:disabled {
  background-color: #81b3fe;
}

/* element right action button collect disabled */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button.collect-button:disabled {
  background-color: #b1d599;
}

/* element right action button hover */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button:hover {
  background-color: #015ce3;
}

/* element right action button collect hover */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > .action-button.collect-button:hover {
  background-color: #368700;
}

/* element right button icon */
.container-admin-session > #container-right-content > #container-elements > .container-element > .right-content > button > svg {
  margin-right: 8px;
}

/**
*** SECTION SPECTATEUR ***
**/
/* container */
.container-admin-session > #container-right-content > #container-elements > #container-spectator {
  position: relative;
  width: 98%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px;
  margin-top: 96px;
  border-radius: 8px;
  box-sizing: border-box;
  filter: var(--shadow);
  background-color: var(--color-1);
}
/* title */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > p {
  margin: 36px 0px 18px 0px;
  font-family: var(--font-family-bold);
  font-size: 18px;
  color: var(--input-background);
  text-align: center;
}
/* title indication */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > p > span {
  font-family: var(--font-family-regular);
  font-size: 12px;
}
/* container new tab */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > .container-new-tab {
  position: absolute;
  top: 8px;
  right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: none;
  border-radius: 4px;
  background-color: #0267FD;
  cursor: pointer;
}
/* container new tab hover */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > .container-new-tab:hover {
  background-color: #015ce3;
}
/* text new tab */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > .container-new-tab > p {
  margin: 0px;
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--input-background);
  text-align: center;
}
/* icone new tab */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > .container-new-tab > svg {
  margin-left: 4px;
  color: var(--input-background);
}
/* iframe */
.container-admin-session > #container-right-content > #container-elements > #container-spectator > #iframe-spectator {
  height: 800px;
  flex: 1;
}
